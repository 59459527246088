@import '/src/styles/index.scss';

.title {
  margin-bottom: 8px;
}
.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  height: fit-content;
}

.content {
  width: 100%;
  @include flex(row, center, space-between, nowrap);

  // colors
  &_gray-dark {
    background: $bg-input-gray;
    border: $border-default;
    border-radius: $radius-btn-default;
    color: $color-white;
    transition: all $transition-default-time;

    &:focus-within {
      border: $border-input-active-white;
    }
    &:hover {
      border: $border-input-hover-gray;
    }
  }

  &_transparent {
    @extend .content_gray-dark;
    background: transparent;
  }
  &.md {
    padding: 0 16px;
    height: 42px;
    font-size: 14px;
    line-height: 24px;
  }
  &.lg {
    padding: 0 10px 0 16px;
    width: 100%;
    min-width: 400px;
    height: 52px;
    @include b(mobile) {
      min-width: 200px;
    }
  }

  &.textArea {
    width: 655px;
    min-height: 158px;
    background: $bg-card-dark-black;

    @include b(tablet) {
      width: 100%;
    }
    textarea {
      padding: 10px 0;
      width: 100%;
      min-height: 100%;
      background: transparent;
      border: none;
      resize: none;
      color: $color-white;
      &::placeholder {
        max-width: 302px;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}

.input {
  width: 100%;
  background: transparent;
  border: none;
  padding: 13px 20px;
  color: $color-white;
  resize: none;

  &::placeholder {
    color: $color-white;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.error {
  position: absolute;
  left: 10px;
  top: calc(100% + 5px);

  &Border {
    border: $border-btn-red;
  }
}

.subtitle {
  padding: 0 10px;
  margin-top: 5px;
}

// for other component

// .date {
// }

// .textarea {
// }

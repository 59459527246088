@import '/src/styles/index';

.container {
  width: 265px;
  height: 100%;
  background: $bg-card-dark-black;

  span {
    margin-bottom: 10px;
  }

  ul {
    height: 100%;
    @include flex(column, center, center, nowrap);
    gap: 10px;

    li {
      width: 100%;
      .btn {
        width: 100%;
        @include flex(row-reverse, center, flex-end, nowrap);
        gap: 10px;
      }
    }
  }
}

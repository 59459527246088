@import '/src/styles/index';

.wrapper {
  margin: 0 auto;
  width: 100%;
  height: 130px;
  @include flex(row, center, center, nowrap);
  background: transparent;
  border-bottom: $border-default;
}

.logo {
  min-width: 130px;
  height: 130px;
  @include flex(row, center, center);
  img {
    width: 69px;
    height: 83px;
  }
  border: {
    right: $border-default;
    bottom: $border-default;
    left: $border-default;
  }
  @include b(tablet) {
    display: none;
  }
}

.container {
  width: 100%;
  max-width: 1920px;
  @include flex(column, center, center);
}

.balance {
  @include flex(column, flex-start, center, nowrap);

  &Text {
    width: 100%;
    @include flex(row, center, flex-start, nowrap);
    gap: 5px;

    span {
      &:first-child {
        @include ellipsis(80px);
      }
    }
  }
}

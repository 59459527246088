@import '/src/styles/index';

.card {
  width: 100%;
  height: 500px;
  @include flex(column, center, space-between, nowrap);

  .container {
    padding: 40px 0;
    width: 100%;
    height: 100%;
    @include flex(column, flex-start, space-between, nowrap);

    .title {
      width: 100%;

      @include flex(row, center, space-between, nowrap);
    }

    .row {
      width: 100%;
      @include flex(column, flex-start, flex-start, nowrap);
      gap: 10px;
    }
  }
  .bottom {
    padding-top: 20px;
    width: 100%;
    @include flex(column, flex-start, space-between, nowrap);
    gap: 20px;
  }
}

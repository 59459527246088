@import '/src/styles/index';

.poolFormCard {
  width: 100%;
  margin-bottom: 65px;
  .title {
    margin: 32px 0 16px;
  }
  .container {
    width: 100%;
    @include flex(column, center, center, nowrap);

    .tab {
      width: 100%;
      @include flex(column, center, center, nowrap);

      li {
        padding: 24px 0;
        width: 100%;
        @include flex(column, flex-start, center, nowrap);
        &:not(:last-child) {
          border-bottom: $border-default;
        }

        h3 {
          margin-bottom: 16px;
          &::first-letter {
            text-transform: uppercase;
          }
        }

        .subtitle {
          padding-bottom: 8px;
        }

        p {
          font-size: 16px;
          line-height: 22px;
          color: $color-gray;
          strong {
            font-weight: 400;
            color: $color-white;
          }
        }
        .row {
          width: 100%;
          @include flex(row, flex-end, space-between, nowrap);
        }

        .input {
          margin: 22px 0;
        }

        .fundingSubtitle {
          margin: 32px 0 9px;
        }

        .circle {
          margin-right: 10px;
          width: 23px;
          height: 23px;
          border-radius: 50%;
          background: $bg-circle-funding-balance;
        }
        .fundBtn {
          margin: 25px 0 32px;
          width: 234px;
        }
      }
    }
  }

  .claimBtn {
    margin-top: 20px;
    width: 100%;
  }
}

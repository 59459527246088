.Toastify__toast-container {
  width: 350px;
  word-break: break-word;

  @include b(mobile) {
    width: 100%;
  }
}
.Toastify__toast-body {
  background: none !important;
  box-shadow: none !important;
  border-radius: 50% !important;
}
.Toastify__toast {
  margin: 5px 0;
  display: flex;
  box-shadow: none;
  color: none;
  overflow: visible;
  border-radius: 30px;
  width: 100%;
  overflow: visible;
  border-radius: 12px !important;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  // & > svg {
  //   position: absolute;
  //   right: 16px;
  //   top: 20px;
  //   fill: rgba(255, 255, 255, 1);
  // }

  &--success {
    background: $bg-toast-success;
    border: $border-toast-success;
    color: $color-green;
  }

  &--error {
    background: $bg-toast-error;
    border: $border-toast-error;
    color: $color-red;
  }

  &--info {
    background: $bg-toast-info;
    border: $border-toast-info;
    color: $color-white;
  }
  &--warning {
    background: $bg-toast-warning;
    border: $border-toast-warning;
    color: $color-yellow;
  }
}

.Toastify__close-button {
  align-self: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: rgba(255, 255, 255, 1);
  // background: blue;

  // & > svg {
  //   display: none;
  // }

  // // custom cross
  // &:after {
  //   content: '\e80a';
  // }
}

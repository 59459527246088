@import '/src/styles/index';

.container {
  padding: 0 14px;
  width: fit-content;
  height: 32px;
  @include flex(row, center, center, nowrap);
}
// status
.staking {
  background: $bg-status-green;
  border: $border-status-green;
  border-radius: $radius-status-default;
}
.funding {
  @extend .staking;
}
.distributing {
  @extend .staking;
}
.completed {
  @extend .staking;
  background: $bg-card-dark-opacity;
  border: $border-text-gray;
}

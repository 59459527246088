@import '/src/styles/index';

.root {
  h3 {
    align-self: flex-start;
  }
}

.connect {
  width: 100%;
  min-height: 350px;
  @include flex(column, center, flex-start, nowrap);

  @include b(mobile) {
    min-height: auto;
  }

  span {
    margin: 14px 0 32px;
    align-self: flex-start;
  }
  p {
    display: inline;
    width: 400px;
    margin-top: 10px;
    span {
      display: inline;
      margin: 0;
    }
  }
  &Btn + &Btn {
    margin: 16px 0 32px;
  }
  &Btn {
    @include b(tablet) {
      max-height: 50px;
    }
  }
}

.signIn {
  width: 100%;
  @include flex(column, center, flex-start, nowrap);

  &Title {
    margin-bottom: 20px;
    width: 100%;
    @include flex(row, center, space-between, nowrap);
  }
  &Input + &Input {
    margin: 25px 0 8px;
  }
  &Forgot {
    padding: 0 !important;
    align-self: flex-start;
  }
  &Submit {
    margin-top: 40px;
    width: 100%;
    min-height: 40px;
  }
  &Bottom {
    margin-top: 24px;
    padding: 0 35px;
    @include flex(row, center, space-between, nowrap);

    @include b(tablet) {
      padding: 0;
      width: 100%;
      @include flex(column, flex-start, flex-start, nowrap);
    }

    span {
      white-space: nowrap;
    }

    &Btn {
      @include b(tablet) {
        padding: 0 !important;
      }
    }
  }
}

.loginAndAuth {
  @include flex(column, flex-start, center, nowrap);
  &Subtitle {
    margin: 14px 0 32px;
  }
  &Warning {
    max-width: 400px;
    width: 100%;
  }
  &Btn {
    margin-top: 10px;
    width: 100%;
    max-width: 400px;
  }
}

.resetPassword {
  form {
    width: 100%;
    height: 100%;
    @include flex(column, center, space-between, nowrap);
  }
  &Input {
    margin: 25px 0 20px;
  }
  &Btn {
    margin-bottom: 25px;
    width: 100%;
  }
}

@import '/src/styles/index.scss';

.input {
  width: 100%;
  border: none;
  background: transparent;
  color: inherit;
  &::-webkit-calendar-picker-indicator {
    background-image: url(/assets/img/section/common/calendar.svg);
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // &::file-selector-button {
  //   position: absolute;
  //   top: 80px;
  //   left: 0;
  //   right: 0;
  //   margin: 0 auto;
  //   // width: 100%;
  //   width: 469px;
  //   // height: 200px;
  //   height: 56px;
  //   background: url(/assets/img/section/common/plus-circle.png) center top no-repeat;
  //   border: none;
  //   color: transparent;
  //   // color: $color-gray;
  // }
}

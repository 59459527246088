@import '/src/styles/index.scss';

.checkbox {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  position: relative;
  &Label {
    @include flex(row, flex-start, flex-start, nowrap);
    cursor: pointer;
  }
  &Text {
    margin-left: 16px;
    margin-top: 3px;
  }
  &-input {
    position: absolute;
    left: 0;
    z-index: 9999;
    cursor: pointer;
    opacity: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
  &-inner {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    box-sizing: border-box;
    background: $bg-steps-progress-gray-item;
    border-radius: $radius-checkbox;
  }
  &-checked &-inner {
    &::before {
      content: '';
      display: block;
      background: url(/assets/img/section/common/check.svg) center no-repeat, $bg-nav-red;
      border-radius: $radius-checkbox;
      width: 100%;
      height: 100%;
    }
  }
}

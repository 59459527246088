@import '/src/styles/index.scss';

.uploader {
  &.lg {
    text-align: center;
    width: 693px;
    height: 280px;
    @include flex(column, center, center, nowrap);
    background: $bg-card-dark-opacity;
    border: $border-default;
    border-radius: $radius-card-default;
    transition: all $transition-default-time;

    @include b(tablet) {
      // padding: 10px;
      width: 100%;
      img {
        border-radius: $radius-card-default;
      }
    }

    &:hover {
      background: $bg-upload-hover !important;
    }
  }
  &.error {
    border: $border-btn-red !important;
  }

  &.circle {
    width: 100%;
    height: 100px;
    @include flex(row, center, flex-start, nowrap);
    cursor: pointer;
  }

  &Wrapper {
    position: relative;
    @include b(tablet) {
      padding: 10px;
      width: 100%;
    }
  }

  &Title {
    &.lg {
      margin: 15px 0 30px;
      font-size: 20px;
      line-height: 32px;
    }

    &.circle {
      font-size: 14px;
      color: $color-red;
      font-size: 14px;
      border-bottom: $border-link;
    }
  }

  &Subtitle {
    padding: 10px 13px;
    max-width: 469px;
    border: $border-default;
    border-radius: $radius-btn-default;
    @include b(tablet) {
      margin: 0 10px;
    }
  }

  &Img {
    &.circle {
      margin-right: 10px;
      border-radius: 50%;
      &:hover {
        background: $bg-upload-hover !important;
      }
    }
    &.error {
      border: $border-btn-red !important;
    }
  }

  &Loaded {
    &.lg {
      position: relative;
      width: 100%;
      height: 100%;
    }
    &.circle {
      width: 100%;
      height: 100px;
      @include flex(row, center, flex-start, nowrap);
      cursor: pointer;
    }

    &Preview {
      &.lg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
      }
      &.circle {
        margin-right: 10px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: $border-default;
      }
    }

    &Btn {
      position: absolute;
      margin: 0 auto;
      bottom: 20px;
      left: 0;
      right: 0;
      z-index: 2;
    }
  }
}
.uploaderError {
  margin: 0 auto;
  position: absolute;
  bottom: 5px;
  color: $color-orange;
  // color: $red;
}

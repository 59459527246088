@import '/src/styles/index';

.spinner {
  animation: rotate 1s linear infinite;
}

.preloader {
  width: 100%;
  min-height: 100vh;
  @include flex(row, center, center, nowrap);
  background: url(/assets/img/section/common/bg-flash.png) left top no-repeat,
    url(/assets/img/section/common/bg-main.png) center top/100% auto no-repeat,
    $bg-main;
  color: $color-white;
}

.lg {
  width: 150px;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(1turn);
  }
}

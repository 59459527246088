@import '/src/styles/index';

.connectFormCard {
  min-width: 480px;
  height: 200px;
  @include flex(column, flex-start, flex-start, nowrap);
  @include b(mobile) {
    margin: 0 0 40px;
    min-width: 100%;
  }

  .controls {
    margin-top: 20px;
    width: 100%;
    @include flex(row, center, flex-start, nowrap);
    gap: 30px;
  }
}

@import '/src/styles/index';

.warning {
  padding: 15px 17px;
  max-width: 400px;
  @include flex(row, flex-start, flex-start, nowrap);
  gap: 20px;
  background: $bg-warning-orange;
  border: $border-warning-ornge;
  border-radius: $radius-warning;

  div {
    max-width: 270px;
    line-height: 24px;
  }

  &.md {
    height: 102px;
    @include b(mobile) {
      height: auto;
    }
  }
  &.fc {
    height: auto;
  }
}

@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $format: 'woff') {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
    @if $format == 'woff' {
      src: url('#{$file-path}.#{$format}') format($format);
    } @else {
      src: url('#{$file-path}.#{$format}') format('truetype');
    }
  }
}

@mixin flex($direction: row, $alignment: normal, $justification: normal, $wrap: wrap) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justification;
  align-items: $alignment;
  flex-wrap: $wrap;
}

@mixin grid($columns: repeat(2, 1fr), $rows: 1fr, $gap: 10px) {
  display: grid;
  grid-template-columns: $columns;
  grid-auto-rows: $rows;
  gap: $gap;
}

@mixin text-control($size: 15px, $height: 18px, $weight: 400) {
  font-size: $size;
  font-weight: $weight;
  line-height: $height;
}

@mixin text-common($point, $weight: 400) {
  @if $point==ssm {
  }
  @if $point==sm {
  }
  @if $point==smd {
  }
  @if $point==md {
  }
  @if $point==slg {
  }
  @if $point==lg {
  }
  @if $point==xl {
  }
  @if $point==xxl {
  }
  font-weight: $weight;
}

@mixin ellipsis($width: 100%) {
  max-width: $width;
  width: fit-content;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

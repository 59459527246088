@import '/src/styles/index';
$row-size: calc(100% - $width-default-header-select) $width-default-header-select;

.row {
  width: 100%;
  height: 65px;

  border-top: $border-default;

  .item {
    &Nav {
      padding-top: 15px;
      margin: 0 auto;
      width: 100%;
      max-width: 1200px;
      @include flex(row, center, center, nowrap);
      gap: 100px;

      @include b(tablet) {
        margin: 0;
        padding: 0 35px;
        width: 100%;
        height: 65px;
        @include flex(row, center, flex-start, nowrap);
        gap: 35px;

        overflow-x: scroll;
        overflow-y: hidden;
        &::-webkit-scrollbar {
          display: none;
        }
      }

      // @include b(extramobile) {
      //   width: 100%;
      // }

      a {
        @include flex(row, center, center, nowrap);
        gap: 10px;
        color: $color-white;
        @include b(mobile) {
          white-space: nowrap;
        }

        .totalValue {
          padding: 0 10px;
          width: 36px;
          height: 20px;
          @include flex(row, center, center, nowrap);

          font-size: 14px;
          background: $bg-select-hover;
          border-radius: $radius-status-header-total-value;
        }
      }
    }
    &Balance {
      padding: 0 20px;
      @include flex(column, flex-start, center);
      gap: 5px;
      border: {
        bottom: $border-default;
        left: $border-default;
        right: $border-default;
      }

      &Text {
        width: 100%;
        @include flex(row, center, flex-start, nowrap);
        gap: 5px;

        span {
          &:first-child {
            @include ellipsis(80px);
          }
        }
      }

      @include b(tablet) {
        display: none;
      }
    }
  }

  &:last-child {
    display: grid;
    grid-template-columns: $row-size;
    gap: 0;
    @include b(tablet) {
      @include flex(row, center, flex-start, nowrap);
    }
  }
}

.notActive {
  span {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: -15px;
      height: 2px;
      width: 0;
      background: $bg-nav-red;
    }
  }
}

.active,
.notActive:hover {
  span {
    position: relative;
    color: $color-red;
    transition: color $transition-default-time;
    &:after {
      content: '';
      position: absolute;
      bottom: -15px;
      height: 2px;
      width: 100%;
      background: $bg-nav-red;
      transition: width $transition-default-time;
    }
  }
  .totalValue {
    background: $bg-nav-red !important;
  }
}

.signup {
  padding: 15px 0 0 30px;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  @include flex(row, center, flex-start, nowrap);

  gap: 10px;
}

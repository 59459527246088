@import '/src/styles/index';

.switcher {
  padding-bottom: 17px;
  width: 100%;
  @include flex(row, center, flex-start, nowrap);
  border-bottom: $border-default;
  li {
    &:not(:first-child) {
      margin-left: 44px;
      @include b(mobile) {
        margin-left: 24px;
      }
    }
  }
  @include b(mobile) {
    max-width: 350px;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.active {
  position: relative;
  color: $color-red !important;
  &:after {
    content: '';
    position: absolute;
    bottom: -18px;
    height: 2px;
    width: 100%;
    background: $bg-nav-red;
    transition: width $transition-default-time;
  }
}

@import '/src/styles/index';

//---------- desctop grid size
$main-row-size-before-connect: 80% 20%;
$main-row-size-after-connect: 35% 65%;
$main-row-size-after-signed: 55% 45%;
$static-grid-size: 45% 55%;
$login-grid-size: 30% 30% 40%;

$finish-grid-size: calc((100% - $width-default-header-dots) / 2) calc((100% - $width-default-header-dots) / 2)
  $width-default-header-dots;

//---------- mobile grid size
$mobile-finish-grid-size: $width-default-header-dots;

.row {
  width: 100%;
  height: 65px;
  display: grid;
  grid-template-columns: $main-row-size-before-connect;
  gap: 0;

  @include b(tablet) {
    @include flex(row, center, flex-end, nowrap);
  }

  &Connected {
    grid-template-columns: $main-row-size-after-connect;
  }

  &Signed {
    grid-template-columns: $main-row-size-after-signed;
  }

  &Grid {
    &Static {
      display: grid;
      grid-template-columns: $static-grid-size;

      @include b(tablet) {
        width: 100%;
        @include flex(row, center, flex-start, nowrap);
      }

      .item {
        &:nth-child(1) {
          @include flex(row, center, center, nowrap);

          &:hover {
            background: $bg-select-hover;
          }
          // mobile logo
          @include b(tablet) {
            padding: 0;
            width: 74px;
            height: 65px;
            @include flex(row, center, center, nowrap);

            border: {
              right: $border-default;
              left: $border-default;
            }

            &:hover {
              background: transparent;
            }

            img {
              width: 38px;
              height: 49px;
            }
          }
        }

        // hidden desktop static contorls
        &:nth-child(2),
        &:nth-child(3) {
          @include b(tablet) {
            display: none;
          }
        }
      }
    }

    &Login {
      display: grid;
      grid-template-columns: $login-grid-size;
      @include b(tablet) {
        width: 100%;
        height: 100%;
        @include flex(row, center, flex-end, nowrap);
      }

      .item {
        &:nth-child(1) {
          width: 100%;
          @include b(tablet) {
            width: $width-default-header-dots;
            @include flex(row, center, center, wrap);
            border-left: $border-default;
          }
          &:hover {
            background: $bg-select-hover;
          }
        }

        &:nth-child(2) {
          @include flex(row, center, center, wrap);
          // @include b(tablet) {
          //   width: $width-default-header-dots;
          //   @include flex(row, center, center, wrap);
          //   border-left: $border-default;
          // }
        }

        &:nth-child(4) {
          display: none;
          @include b(tablet) {
            display: block;
          }
        }
      }
    }

    &Finish {
      display: grid;
      grid-template-columns: $finish-grid-size;
      @include b(tablet) {
        grid-template-columns: $mobile-finish-grid-size;
      }

      .item {
        &:nth-child(1) {
          border-right: none;
          &:hover {
            background: $bg-select-hover;
          }
        }

        &:nth-child(2) {
          width: 100%;
          @include flex(row, center, center, nowrap);
          border-left: $border-default;
        }

        &:nth-child(3) {
          padding: 0;
          @include flex(column, center, center, nowrap);
          &:hover {
            background: $bg-select-hover;
          }
          @include b(tablet) {
            border-left: $border-default;
          }
        }

        &:nth-child(1),
        &:nth-child(2) {
          @include b(tablet) {
            display: none;
          }
        }
      }
    }

    &Connect {
      width: 100%;
      height: 100%;
      @include flex(row, center, flex-end, nowrap);
    }
  }

  .item {
    padding: 0 20px;
    height: 100%;
    @include flex(column, flex-start, center, nowrap);
    gap: 5px;
    white-space: nowrap;
    border-right: $border-default;
    transition: all $transition-default-time;
    @include b(tablet) {
      padding: 0;
      border-bottom: none;
    }

    &Btn {
      padding: 0 20px;
      width: 100%;
      height: 100%;
      @include flex(row, center, center, nowrap);
      gap: 10px;
      border-right: $border-default;

      @include b(tablet) {
        @include flex(column, flex-end, center, nowrap);
      }
    }

    &Price {
      strong {
        margin-left: 5px;
        color: $color-white;
        font-weight: 400;
      }
    }

    &Link {
      strong {
        margin-left: 5px;
        color: $color-red;
        font-weight: 400;
        border-bottom: $border-link;
      }
    }

    &Network {
      @include flex(column, flex-start, flex-start, nowrap);
      gap: 5px;
      width: 100%;
    }
  }
}

.modal {
  position: absolute;
  top: 50px;
  margin-left: 35%;
  padding: 25px;

  width: 265px;
  min-height: 230px;

  background: none;
  border: none;
  @include flex(row, center, flex-end, nowrap);

  @include b(mobile) {
    margin: 0 auto;
    @include flex(row, center, center, nowrap);
  }

  &Wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    @include flex(row, center, center, nowrap);
  }
}

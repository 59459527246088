@import '/src/styles/index.scss';

.wrapper {
  margin: 40px 0;
  width: 780px;
  height: 480px;
  @include flex(column, center, center, nowrap);
  border: $border-default;
  border-radius: $radius-card-default;
}

.baner {
  padding: 31px 24px;
  width: 778px;
  height: 287px;
  @include flex(row, flex-start, flex-end, nowrap);
  background: url(/assets/img/section/home/banner-bg.png) center no-repeat;

  .status {
    width: 176px;
    height: 32px;
    background: $bg-status-green;
    border: $border-status-green;
    border-radius: $radius-status-default;
  }
}

.container {
  width: 100%;
  height: 192px;
  display: grid;
  grid-template-columns: 70% 30%;
  background: $bg-card-dark-opacity;
  border-top: $border-default;
  gap: 0;

  &Text {
    padding: 24px 16px;
    max-height: 192px;
    border-right: $border-default;
    h2 {
      margin: 0 0 10px 0 !important;
    }

    p {
      max-width: 451px;
    }
  }

  &Info {
    padding: 17px;
    @include flex(column, flex-start, space-between);

    .item {
      @include flex(column, flex-start, center, nowrap);
      gap: 8px;
      div {
        @include flex(row, center, flex-start, nowrap);
        gap: 5px;
      }
    }
  }
}

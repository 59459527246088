@import '/src/styles/index';

.copyAddress {
  width: 100%;
  @include flex(row, center, flex-start, nowrap);
  cursor: pointer;
  img {
    width: 16px;
    height: 18px;
  }

  &.btn {
    padding: 10px 16px;
    height: 42px;
    background: $bg-input-gray;
    border: $border-default;
    &:hover {
      @extend .btn;
      transform: none;
    }
  }
  .addressText {
    width: 316px;
    overflow: hidden;
    @include ellipsis();
    display: inline-block;
    white-space: nowrap;
  }
}

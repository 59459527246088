@import '/src/styles/index';

.wrapper {
  padding: 10px 0;

  width: 100%;
  height: 100%;
}
.textArea {
  margin-top: 8px;
}

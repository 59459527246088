@import '/src/styles/index';

.card {
  margin-top: 16px;
  width: 480px;
  height: 304px;
  @include flex(column, center, space-between, nowrap);
  @include b(mobile) {
    width: 100%;
  }

  .container {
    width: 100%;
    height: 100%;
    @include flex(column, center, space-between, nowrap);

    .row {
      width: 100%;
      @include flex(row, center, space-between, nowrap);
    }
  }
}

@import '/src/styles/index';

.progressCard {
  position: relative;
  width: 100%;
  .container {
    padding: 25px 25px 40px; 
    margin-top: 16px;
    width: 100%;
    height: 304px;
    @include flex(column, center, space-between, nowrap);

    .top {
      width: 100%;
      @include flex(row, flex-end, space-between, nowrap);

      .totalStaked {
        .subtitle {
          margin-bottom: 8px;
        }
      }
      img {
        margin-right: 5px;
      }
    }
    .progress {
      width: 100%;
    }
  }

  .border {
    position: absolute;
    top: 125px;
  }
}

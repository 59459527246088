@import '/src/styles/index';

.projectFormCard {
  width: 678px;
  min-height: 767px;
  @include flex(column, center, flex-start, nowrap);
  @include b(mobile) {
    width: 100%;
  }

  .projectPoster {
    margin: 16px auto;
    width: 100%;
    height: 287px;
    border-radius: $radius-card-default;
  }
  .raisBlock {
    padding-bottom: 35px;
    width: 100%;
    @include flex(row, center, flex-start, nowrap);
    gap: 50px;
    border-bottom: $border-default;
    @include b(tablet) {
      gap: 10px;
      white-space: nowrap;
    }

    div {
      @include flex(column, flex-start, center, nowrap);
      gap: 8px;

      .network {
        @include flex(row, center, flex-start, nowrap);
        gap: 5px;
      }
    }
  }

  .summary {
    padding: 35px 0;
    width: 100%;

    @include flex(column, flex-start, center, nowrap);
    border-bottom: $border-default;

    & > span {
      margin-bottom: 15px;
    }
  }

  .community {
    @extend .summary;
    border: none;

    .links {
      width: 100%;
      @include flex(row, center, flex-start, nowrap);
      gap: 10px;
    }

    .whitepaperBtn {
      margin-top: 20px;
    }
  }

  .title {
    margin: 35px 0;
    width: 100%;
  }

  .tokenomicsList {
    margin-left: 20px;
    align-self: flex-start;
    li {
      margin: 0 0 10px 5px;
      position: relative;
      width: 100%;
      @include flex(row, flex-start, flex-start, wrap);
      // @include b(tablet) {
      //   @include flex(column, flex-start, center, nowrap);
      // }

      &::before {
        position: absolute;
        left: -15px;
        content: '\2022';
        color: $color-gray;
        font-weight: bold;
        display: inline-block;
        width: 3px;
      }

      span {
        margin-right: 10px;
        white-space: nowrap;
      }
    }
  }

  .bottomNote {
    margin-top: 35px;
  }

  .team {
    width: 100%;
    @include flex(column, flex-start, flex-start, nowrap);
    &Item {
      margin-top: 35px;
      overflow: hidden;

      @include flex(column, flex-start, flex-start, nowrap);
      &Top {
        @include flex(row, center, flex-start, nowrap);

        span {
          margin: 0 10px;
        }
      }
      &Avatar {
        width: 70px;
        height: 70px;
        border: $border-default;
        border-radius: 50%;
      }
      p {
        margin: 35px 0 35px 72px;
        max-width: 517px;
        overflow-wrap: break-word;
      }
      a {
        margin-left: 72px;
      }
    }
  }
}

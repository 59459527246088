$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
);

// Mobile first
$mediaWidthLaptop: 1440px;
$mediaWidthDesktop: 1024px;
$mediaWidthTablet: 768px;
$mediaWidthMobile: 550px;

$font-size-default: 15px;
$line-height-default: 18px;

// bckgrounds
$bg-main: rgba(28, 32, 34, 1);
$bg-gray-scrollbar: rgb(163, 163, 163);
$bg-card-dark-opacity: rgba(42, 46, 48, 0.5);
$bg-card-dark-black: rgba(28, 32, 34, 1);
$bg-card-poweredby: rgba(255, 255, 255, 0.06);
$bg-wrapper-modal: rgba(0, 0, 0, 0.9);
$bg-nav-red: rgba(246, 90, 56, 1);
$bg-btn-red: rgba(255, 75, 35, 1);
$bg-btn-green: rgba(26, 192, 87, 1);
$bg-btn-gray: rgba(66, 64, 64, 1);
$bg-status-green: rgba(84, 169, 83, 0.25);
$bg-input-gray: rgba(26, 26, 26, 0.3);
$bg-select-hover: rgba(66, 64, 64, 1);
$bg-warning-orange: rgba(246, 116, 22, 0.1);
$bg-steps-progress-black-opacity: rgba(255, 255, 255, 0.05);
$bg-steps-progress-gray-item: rgba(255, 255, 255, 0.4);
$bg-steps-progress-green-item: rgba(26, 192, 87, 1);
$bg-upload-hover: rgb(50, 51, 52);
$bg-circle-line-steps-progress: rgba(50, 52, 56, 1);
$bg-circle-funding-balance: rgba(196, 196, 196, 1);

// --------------
$bg-toast-info: rgba(32, 37, 47, 1);
$bg-toast-warning: rgba(27, 22, 6, 1);
$bg-toast-error: rgba(26, 8, 4, 1);
$bg-toast-success: rgba(13, 28, 1, 1);

// colors
$color-white: rgba(243, 243, 243, 1);
$color-gray: rgba(170, 170, 170, 1);
$color-gray-dark: rgb(170, 170, 170, 0.4);
$color-red: rgba(246, 90, 56, 1);
$color-green: rgba(77, 155, 76, 1);
$color-blue: rgba(42, 171, 238, 1);
$color-yellow: rgba(242, 201, 76, 1);
$color-orange: rgba(242, 201, 76, 1);
$color-orange: rgba(242, 201, 76, 1);
$color-orange-dark: rgba(251, 146, 60, 1);

// borders
$border-default: 1px solid rgba(66, 64, 64, 1);
$border-link: 1px solid rgba(246, 90, 56, 1);
$border-text-gray: 1px solid rgba(170, 170, 170, 1);
$border-btn-gray: 1px solid rgba(77, 79, 80, 1);
$border-btn-red: 1px solid rgba(255, 75, 35, 1);
$border-btn-blue: 1px solid rgba(86, 151, 245, 1);
$border-btn-green: 1px solid rgba(26, 192, 87, 1);
$border-status-green: 1px solid rgba(77, 155, 76, 1);
$border-warning-ornge: 1px dashed rgba(251, 146, 60, 1);
$border-input-hover-gray: 1px solid rgba(170, 170, 170, 1);
$border-input-active-white: 1px solid rgba(243, 243, 243, 1);
// --------------
$border-toast-info: 1px solid rgba(102, 152, 250, 1);
$border-toast-warning: 1px solid rgba(242, 201, 76, 1);
$border-toast-error: 1px solid rgba(255, 64, 64, 1);
$border-toast-success: 1px solid rgba(112, 255, 0, 1);

// border-radius
$radius-btn-default: 8px;
$radius-card-default: 14px;
$radius-status-default: 120px;
$radius-status-header-total-value: 80px;
$radius-warning: 12px;
$radius-checkbox: 4px;

// transition time
$transition-default-time: 0.2s ease-in-out;

// size
$width-default-header-select: 206px;
$width-default-header-dots: 66px;
$width-default-filterbox: 181px;

@import '/src/styles/index';

.currentNetwork {
  width: 100% !important;
  @include b(tablet) {
    @include flex(row, center, flex-start, nowrap);
  }
  img {
    margin-right: 10px;
  }

  .title {
    width: 100%;
    @include flex(row, center, space-between, nowrap);
  }
}

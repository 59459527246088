@import '/src/styles/index';

.modal {
  &Wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include flex(row, center, center, nowrap);
    background: $bg-wrapper-modal;
    transition: all $transition-default-time;
  }

  &Container {
    position: relative;
    padding: 24px 31px;
    background: $bg-card-dark-black;
    border: $border-default;
    border-radius: $radius-card-default;

    h2 {
      margin-bottom: 15px;
    }
  }
}

.fc {
  width: 464px;
  max-height: fit-content;
}

.sm {
  width: 478px;
  min-height: 310px;
  max-height: fit-content;
}

.md {
  width: 478px;
  min-height: 310px;
  max-height: fit-content;
}

.fc,
.md {
  @include b(mobile) {
    width: 80%;
  }
  @include b(extramobile) {
    width: 100%;
  }
}

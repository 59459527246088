@import '/src/styles/index';

.disclaimer {
  width: 849px;
  height: 529px;
  @include b(tablet) {
    width: 100%;
    height: 600px;
    @include flex(row, center, center, nowrap);
  }
  @include b(mobile) {
    width: 380px;
    height: 100vh;
  }
}

.container {
  height: 475px;
  @include flex(row, center, space-between, nowrap);
  gap: 66px;

  @include b(mobile) {
    height: 520px;
  }

  img {
    width: 194px;
    @include b(mobile) {
      display: none;
    }
  }

  .steps {
    position: relative;
    width: 568px;
    height: 100%;

    @include b(tablet) {
      width: 100%;
    }

    @include flex(column, flex-start, space-between, nowrap);

    .content {
      h3 {
        margin-bottom: 16px;
        white-space: pre-line;
        @include b(mobile) {
          min-height: 70px;
        }
      }
      span {
        margin-bottom: 24px;
        min-height: 20px;
      }
      .text {
        max-width: 465px;
        height: 243px;
        overflow-y: auto;
        @include flex(column, flex-start, flex-start, nowrap);
        line-height: 24px;

        li {
          margin-bottom: 5px;
          width: 100%;
          @include flex(row, center, flex-start, wrap);

          a {
            margin-left: 5px;
            color: $color-red;
          }
          p {
            margin-bottom: 2px;
            line-height: 22px;
          }
        }

        &::-webkit-scrollbar {
          width: 5px;
          height: 20px !important;
          background: transparent;
          &-thumb {
            background: $bg-gray-scrollbar;
            border-radius: $radius-btn-default;
          }
        }
      }
    }

    .scrollInfo {
      padding: 16px;
      width: 100%;
      @include flex(row, center, center, nowrap);

      border: {
        top: $border-default;
        bottom: $border-default;
      }
    }

    .bottom {
      width: 100%;
      @include flex(row, center, space-between, nowrap);
      @include b(mobile) {
        margin-top: 20px;
        @include flex(column, center, center, nowrap);
        gap: 10px;
      }

      .btns {
        width: 100%;
        @include flex(row, center, flex-end, nowrap);
        gap: 16px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: -25px;
      left: -33px;
      height: 529px;
      width: 1px;
      background: $bg-select-hover;

      @include b(tablet) {
        display: none;
      }
    }
  }
}

@import '/src/styles/index.scss';

.wrapper {
  @include b(mobile) {
    width: 100%;
  }
}

.select {
  box-sizing: border-box;
  border: none;
  cursor: pointer;
  position: relative;
  @include flex(row, center, space-between, nowrap);

  &.gray,
  &.gray .optionWrapper {
    border-radius: $radius-btn-default;
    border: $border-default;
    background: $bg-card-dark-opacity;
  }
  &.white,
  &.white .optionWrapper {
    @extend .gray;
  }

  &.mini {
  }
  &.big {
  }
  &.form {
    margin-bottom: 35px;
  }
  &.currency {
    font-weight: 500;
  }
  &.active {
    background: transparent;
  }
}

.currentOption {
  position: relative;
  @include flex(row, center, flex-start, nowrap);
  width: 100%;

  &Wrapper {
    @include flex(row, center, center);
    width: 100%;
    &.mini {
      @include flex(row, center, flex-start, nowrap);
    }
    &.form {
      @include flex(row, flex-start, flex-start, nowrap);
    }
  }
  &.mini {
    padding: 0 16px;
    width: $width-default-filterbox;
    height: 48px;
  }
  &.big {
    width: $width-default-header-dots;
    height: 65px;
    align-self: flex-end;
  }
  &.form {
    padding: 16px 14px;
    width: 268px;
    height: 42px;
  }
  &.gray {
    color: $color-gray;
    font-size: 16px;
  }
  &.white {
    color: $color-white;
    font-size: 16px;
  }
}

.arrow {
  transition: transform 0.2s ease-in-out;
  &.active {
    transform: rotate(180deg);
  }
}

.optionWrapper {
  position: absolute;
  overflow: hidden;
  width: 100%;
  margin-top: 2px;
  z-index: 999;

  &.mini {
    top: 100%;
    width: $width-default-filterbox;
  }
  &.big {
    top: calc(100% - 2px);
    left: -139px;
    width: $width-default-header-select;
    @include b(mobile) {
      left: inherit;
      right: 0;
      // right: -21px;
    }
  }
  &.form {
    top: calc(100% - 2px);
    width: 268px;
  }
}

.option {
  left: 0;
  padding: 5px 10px;
  position: relative;
  z-index: 999;
  @include flex(row, center);
  transition: all $transition-default-time;

  &.dark {
    height: 65px;
    background: $bg-card-dark-black;
    border: $border-default;
    &:hover {
      background: $bg-select-hover;
    }
    &.active {
      background: transparent;
    }
  }

  &.gray {
    height: 48px;
    background: $bg-card-dark-black;
    color: $color-gray;
    &:hover {
      background: $bg-select-hover;
    }
    &:not(:first-child) {
      border-top: $border-default;
    }
  }
  &.white {
    @extend .gray;
  }
}

.img {
  height: fit-content;
  @include flex(row, center);
  &Currency {
    img {
      min-width: 25px;
    }
  }
}

.title {
  margin-bottom: 5px;
}
.subtitle {
  margin-top: 5px;
  padding-left: 10px;
}
.error {
  padding-left: 10px;
}

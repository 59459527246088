@import '/src/styles/index';

.container {
  padding: 30px 16px;
  width: 380px;
  height: 144px;
  @include flex(column, flex-start, space-between, nowrap);
  @include b(extramobile) {
    width: 100%;
  }
}

.text {
  display: block;
  line-height: 30px;
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
}

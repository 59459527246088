@import '/src/styles/index';

.projectCards {
  padding-bottom: 35px;
  width: 100%;
  .container {
    width: 100%;

    @include grid(repeat(3, 1fr));
    grid-gap: 20px !important;

    @include b(tablet) {
      @include flex(row, center, center, wrap);
    }

    @include b(mobile) {
      display: block;
    }

    .cardItem {
      margin: 20px 0;
    }
  }
}

@import '/src/styles/index';

.rc-dialog-close {
  position: absolute;
  top: 0;
  right: -50px;
  background: transparent;
  border: none;
  transition: all $transition-default-time;
  &:hover,
  &:focus {
    transform: perspective(100px) translate3d(0, 0, -10px);
  }
  @include b(mobile) {
    display: none;
  }
}

// .chooseWrapper {
//   position: fixed;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   @include flex(row, center, center, nowrap);
// }

// .chooseNetwork {
//   position: absolute;
//   top: 70px;
//   left: 0;
//   min-height: 200px;
//   width: 300px;
// }

@import '/src/styles/index';

.card {
  width: 380px;
  height: 280px;
  @include b(tablet) {
    width: auto;
    height: auto;
    aspect-ratio: 1.5/1;
  }
  @include flex(column, center, flex-start, nowrap);
  transition: $transition-default-time;

  &:hover {
    transform: translateY(-10px);
    .bottom {
      margin-top: 10px;
      align-self: flex-start;
      .viewBtn {
        img {
          transition: $transition-default-time;
          transform: translateX(7px);
        }
      }
    }
  }
}

.top {
  width: 100%;
  @include flex(row, center, space-between, nowrap);

  &Name {
    width: 100%;
    max-width: 170px;
    white-space: pre-line;
    max-height: 70px;
    overflow: hidden;
    @include flex(row, center, flex-start, nowrap);

    // @include b(mobile) {
    //   max-width: 140px;
    // }

    img {
      margin-right: 10px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
    }
  }
  .tooltipText {
    padding: 5px 10px !important;
    background: $bg-btn-gray;
  }
}
.body {
  margin-top: 25px;
  padding-bottom: 16px;
  width: 100%;
  @include flex(column, center, space-between, nowrap);
  gap: 10px;
  border-bottom: $border-default;
  &Item {
    width: 100%;

    @include flex(row, center, space-between, nowrap);
    img {
      margin-right: 5px;
    }
  }
}

.bottom {
  margin-top: 10px;
  align-self: flex-start;

  .viewBtn {
    padding: 0;
  }
}

@import '/src/styles/index';

// type
.div,
.p,
.span,
.h1,
.h2 {
  width: fit-content;
  @include flex(row, center);

  strong {
    font-weight: 400;
    color: $color-white;
  }
}

.div {
  font-size: 14px;
  line-height: 22px;
}
.span {
  font-size: 14px;
  line-height: 22px;
}
.h1 {
  font-size: 28px;
  line-height: 33px;
}
.h2 {
  font-size: 20px;
  line-height: 24px;
}

// color
.white {
  color: $color-white;
}
.gray {
  color: $color-gray;
}
.gray-dark{
    color: $color-gray-dark;
}
.red {
  color: $color-red;
}
.green {
  color: $color-green;
}
.blue {
  color: $color-blue;
}
.orange {
  color: $color-orange;
}
.orange-dark {
  color: $color-orange-dark;
}

// border
.gray.border {
  border-bottom: $border-text-gray;
}
.red.border {
  border-bottom: $border-btn-red;
}

// size
.uppercase {
  text-transform: uppercase;
}

.f10 {
  font-size: 10px;
  line-height: 12.4px;
}
.f12 {
  font-size: 12px;
  line-height: 14.4px;
}
.f14 {
  font-size: 14px;
  line-height: 22px;
}
.f16 {
  font-size: 16px;
  line-height: 19px;
}
.f18 {
  font-size: 18px;
  line-height: 21px;
}
.f20 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
}
.f22 {
  font-size: 22px;
  line-height: 24px;
  font-weight: 400;
}
.f24 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 400;
}
.f28 {
  font-size: 28px;
  line-height: 32px;
  font-weight: 400;
}
.f30 {
  font-size: 30px;
  line-height: 45px;
  font-weight: 400;
}

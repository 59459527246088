@import '/src/styles/index';

.address {
  @include flex(row, center, flex-start, nowrap);
  gap: 3px;
  img {
      width: 34px;
      height: 34px;
      margin-right: 6px;
    }
}
.text{
    letter-spacing: 1px;
}

@import '/src/styles/index';
.success {
  background: $bg-toast-success;
  border: $border-toast-success;
  &Text {
    color: rgb(43, 43, 43);
  }
}
.info {
  background: $bg-toast-info;
  border: $border-toast-info;
  &Text {
    color: rgb(43, 43, 43);
  }
}
.warning {
  background: $bg-toast-warning;
  border: $border-toast-warning;
  &Text {
    color: rgb(43, 43, 43);
  }
}
.error {
  background: $bg-toast-error;
  border: $border-toast-error;
  &Text {
    color: rgb(43, 43, 43);
  }
}

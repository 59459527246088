@import '/src/styles/index';

.formBorder {
  padding: 0 0 35px;
  position: sticky;
  width: 100%;
  height: 1px;

  div {
    width: 100%;
    height: 1px;
    background: $bg-select-hover;
  }
}

@import '/src/styles/index';

.stepsProgress {
  .title {
    margin-bottom: 50px;
  }

  .flatSteps {
    padding: 4px 8px;
    width: fit-content;
    height: 12px;
    @include flex(row, center, center, nowrap);
    background: $bg-steps-progress-black-opacity;
    border-radius: $radius-btn-default;
    @include flex(row, center, center, nowrap);
    gap: 4px;

    .item {
      &.flat {
        width: 33px;
        height: 4px;
        background: $bg-steps-progress-gray-item;
        border-radius: $radius-btn-default;
        transition: all $transition-default-time;

        &.active.red {
          background: $bg-btn-red;
        }
        &.active.green {
          background: $bg-steps-progress-green-item;
        }
      }
    }
  }
  .circleSteps {
    width: 100%;
    max-width: 500px;
    height: 0px;
    @include flex(row, center, space-between, nowrap);
    background: transparent;
    
    .item {
      position: relative;
      min-height: 70px;
      width: 33%;
      @include flex(column, center, flex-start, nowrap);

      &:after {
        content: '';
        position: absolute;
        top: 12px;
        left: 60%;
        width: 100%;
        height: 2px;
        background: $bg-circle-line-steps-progress;
        z-index: 0;
      }

      &.activeLine{
        &:after{
            background: $bg-steps-progress-green-item;
        }
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      img {
        width: 25px;
        height: 25px;
        z-index: 1;
      }

      &Text {
        margin-top: 15px;
        min-width: 74px;
        text-align: center;
        @include flex(row, center, center);
      }

      &:not(:first-child) {
        margin-left: 20px;
      }
    }
    &.circle {
    }
  }
}

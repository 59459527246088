* {
  box-sizing: border-box;
}

body {
  margin: 0;

  font-family: Roboto, sans-serif;
  font-size: $font-size-default;
  font-weight: 400;
  line-height: $line-height-default;

  color: $color-white;
  background: url(/assets/img/section/common/bg-flash.png) left top no-repeat,
    url(/assets/img/section/common/bg-main.png) center top/100% auto no-repeat, $bg-main;

  span,
  div {
    font-feature-settings: 'liga' off;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  object-fit: cover;
}

a,
input,
textarea,
button {
  font-family: inherit;
}

// .rc-dialog-close {
//   position: absolute;
//   top: 0;
//   right: -50px;
//   background: transparent;
//   border: none;
// }

@import '/src/styles/index';

.notFound {
  height: 320px;
  @include flex(column, center, center, nowrap);
  gap: 40px;

  &.sm {
    width: 100%;
    max-width: 380px;
    height: 280px;
  }

  &.lg {
    width: 100%;
    height: 320px;
  }
}

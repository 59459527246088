@import '/src/styles/index';

.card {
  width: 380px;
  height: 280px;
  @include b(tablet) {
    width: auto;
    min-width: 300px;
    height: auto;
    aspect-ratio: 1.5/1;
  }

  @include flex(column, center, space-between, nowrap);

  .container {
    width: 100%;
    @include flex(column, center, center, nowrap);
    gap: 20px;

    .title {
      width: 100%;

      @include flex(row, center, flex-start, nowrap);
    }
    .row {
      width: 100%;
      @include flex(row, center, space-between, nowrap);
    }
  }

  .bottom {
    margin-top: 20px;
    padding: 20px 0;
    width: 100%;
    align-self: flex-start;
    @include flex(row, center, flex-start, nowrap);
    border-top: $border-default;
  }
}

@import '/src/styles/index';

.wrapper {
  padding: 86px 86px 67px;
  width: 100%;
  height: 390px;
  @include flex(row, flex-start, flex-start, nowrap);
  gap: 70px;

  background: $bg-card-dark-opacity;
  border-top: $border-default;

  @include b(mobile) {
    @include flex(column, flex-start, flex-start, nowrap);
    padding: 42px 42px 45px 26px;
    gap: 18px;
    height: 630px;
  }
}

.logo {
  width: 90px;
  height: 117px;

  @include b(mobile) {
    width: 64px;
    height: 79px;
  }
}

.container {
  width: 100%;
  height: 100%;
  @include flex(column, flex-start, space-between);

  &Text {
    width: 797px;
    height: 120px;
    line-height: 30px;
    @include b(tablet) {
      width: 100%;
      height: 140px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &Bottom {
    width: 100%;
    @include flex(row, center, space-between, wrap);

    @include b(mobile) {
      @include flex(column, flex-start, center, nowrap);
    }

    ul {
      @include flex(row, center, space-between, wrap);
      gap: 20px;

      @include b(mobile) {
        @include flex(column, normal, space-between, wrap);
        gap: 5px;
      }
    }

    .socials {
      @include flex(row, center, space-between, wrap);
      gap: 64px;

      @include b(mobile) {
        @include flex(column, flex-start, space-around, wrap);
        gap: 28px;
        margin-top: 28px;
      }

      ul {
        gap: 5px;

        @include b(mobile) {
          gap: 13px;
          @include flex(row, center, space-between, wrap);
        }
      }
    }
  }
}

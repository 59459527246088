@import 'src/styles/mixins';

.button {
  box-sizing: border-box;
  width: fit-content;

  white-space: nowrap;
  text-decoration: none;

  cursor: pointer;

  background-color: transparent;
  border: none;
  @include flex(row, center, center, nowrap);

  img {
    margin-left: 5px;
  }

  &.ssm {
    padding: 0 18px;
    min-width: 117px;
    height: 32px;
  }
  &.sm {
    padding: 0 18px;
    min-width: fit-content;
    height: 34px;
  }

  &.md {
    padding: 0 16px;
    min-width: 117px;
    height: 36px;
  }

  &.lg {
    padding: 0 16px;
    min-width: 117px;
    height: 48px;
  }

  &.slg {
    width: 100%;
    height: 76px;
  }

  &.icon {
    padding: 0;
    width: fit-content;
    height: fit-content;
  }

  &.default {
    color: $color-white;
    background: $bg-card-dark-opacity;
    border: $border-btn-gray;
    border-radius: $radius-btn-default;
    transition: all $transition-default-time;
    cursor: pointer;
    &:hover,
    &:active {
      transform: perspective(100px) translate3d(0, 0, -5px);
      background: $bg-btn-red;
      border: $border-btn-red;
    }
  }

  &.outline {
    @extend .default;
    border: $border-btn-red;
  }

  &.outline-blue {
    @extend .default;
    border: $border-btn-blue;
  }

  &.outline-green {
    @extend .default;
    border: $border-btn-green;
    &:hover,
    &:active {
      background: $bg-btn-green;
      border: $border-btn-green;
    }
  }

  &.filled {
    @extend .default;
    background: $bg-btn-red;
    border: none;
  }

  &.gray {
    @extend .default;
    background: $bg-btn-gray;
    border: none;
    &:hover,
    &:active {
      border: none;
      transform: none;
    }
  }

  &.back-none {
    color: $color-white;
    background: transparent;
    border: none;
    border-radius: none;
  }

  &.disabled {
    color: $color-gray;
    background: $bg-card-dark-opacity;
    border: $border-btn-gray;
    border-radius: $radius-btn-default;
    cursor: not-allowed;
    &:hover,
    &:active {
      background: $bg-card-dark-opacity;
      border: $border-btn-gray;
    }
  }
}

.reverse {
  @include flex(row-reverse, center, center, nowrap);
}

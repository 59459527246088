@import '/src/styles/index';

.poweredCard {
  width: 141px;
  height: 52px;
  padding: 5px 14px 10px;
  @include flex(column, center, center, nowrap);
  gap: 5px;
  background: $bg-card-poweredby;
  border-radius: $radius-btn-default;
  transition: transform $transition-default-time;
  &:hover {
    transform: perspective(100px) translate3d(0, 0, 10px);
  }

  span {
    font-size: 8px;
    align-self: flex-end;
  }
}

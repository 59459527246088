@import '/src/styles/index.scss';
.mainContainer {
  padding-top: 40px;
  margin: 0 auto;
  width: 100%;
  max-width: 1180px;
  min-height: 100vh;

  @include b(tablet) {
    width: 90%;
  }
}
